<template>
  <section class="section landing">
    <div class="columns is-justify-content-center">
      <div class="column is-6-tablet is-5-desktop is-4-widescreen is-3-fullh">
        <figure class="image mx-auto mb-6" style="width: 140px;">
          <img src="@/assets/logo3.svg" />
        </figure>

        <form @submit.prevent >
          <FormField
            label="First Name"
            v-model="form.firstName"
          />

          <FormField
            label="Last Name"
            v-model="form.lastName"
          />
          
          <FormField
            label="Email"
            type="email"
            v-model="form.email"
          />

          <FormField
            label="Password"
            type="password"
            v-model="form.password"
          />

          <FormField
            label="Company"
            v-model="form.company"
          />

          <article v-if="error" class="message is-danger">
            <div class="message-body">
              {{ error }}
            </div>
          </article>

          <button
            :class="`button is-black is-fullwidth ${isPending && 'is-loading'}`"
            :disabled="!form.email || !form.password || !form.firstName || !form.lastName || !form.company"
            @click="signUpEmail()"
          >
            Sign up
          </button>
        </form>

        <p class="mt-5 has-text-centered mb-3">Already have an account?</p>
        <button class="button is-ghost is-fullwidth mx-auto" @click="showSignInModal = true">
          Log in
        </button>

      </div>
    </div>

    <SignInModal
      v-if="showSignInModal"
      @close="showSignInModal = false"
    />
    
  </section>
</template>

<script>
import { ref, reactive, inject } from 'vue'
import { useRouter } from 'vue-router'
import getUser from '@/composables/getUser'
import useSignup from '@/composables/useSignup'
import useCollection from '@/composables/useCollection'
import Navbar from '@/components/Navbar1.vue'
import FormField from '@/components/Forms/FormField.vue'
import SignInModal from '@/components/Modals/SignInModal.vue'
import { timestamp } from '@/firebase/config'

export default {
  components: { Navbar, FormField, SignInModal },
  setup() {
    const router = useRouter()
    const { user } = getUser()
    const { createUser, createAccount, error } = useSignup()
    const isPending = inject('isPending')
    
    const showSignInModal = ref(false)

    const form = reactive({
      email: '',
      password: '',
      passwordType: '',
      firstName: '',
      lastName: '',
      company: ''
    })
    
    const signUpEmail = async () => {
      isPending.value = true

      console.log('form', form)
      const res = await createUser(form.email, form.password)

      console.log('createUser res', res)
      if (error.value) {
        isPending.value = false
        return
      }

      await createAccount({
        id: res.user.uid,
        email: form.email,
        displayName: `${form.firstName} ${form.lastName}`,
        firstName: form.firstName,
        lastName: form.lastName,
        company: form.company
      })

      const { addDoc: addCampaign } = useCollection('campaigns')
      const firstCampaign = await addCampaign({
        accountId: res.user.uid,
        accountEmail: form.email,
        accountName: `${form.firstName} ${form.lastName}`,
        chooseType: 1,
        mode: 'invite',
        products: [],
        clients: [],
        campaignName: '',
        expiryDate: new Date(new Date().setDate(new Date().getDate() + 30)),
        created: timestamp()
      })
      router.push({ name: 'CreateCampaign', params: { campaignId: firstCampaign.id }})
      isPending.value = false
    }
    
    return {
      user,
      showSignInModal,
      form,

      signUpEmail,
      isPending,
      error,
    }
  }
}
</script>